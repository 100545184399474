import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Text } from "../components/Core"

const PrivacyPages = ({ data, pageContext }) => {
  const { metaTitle, metaDescription } = pageContext
  const post = data.allPrivacyPagesJson.nodes[0]

  return (
    <Layout whiteNav>
      <SEO title={metaTitle} description={metaDescription} schemaPage={null} />

      <div className="nav-desktop-spacer" />

      <div className="container privacy-pages">
        <Text text={post.content} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyPages($title: String!) {
    allPrivacyPagesJson(filter: { title: { eq: $title } }) {
      nodes {
        content
      }
    }
  }
`

export default PrivacyPages
